import 'lazysizes';
import swiper from './ts/swiper';
import 'intersection-observer';
import faq from './ts/faq';
import AOS from 'aos';
import 'aos/dist/aos.css';
import navigation from './ts/navigation';
import popup from './ts/popup';
import banner from './ts/banner';
import cardSlider from './ts/card-slider';
import accordion from './ts/accordion';
import accordionTab from './ts/accordion-tab';
import contactForm from './ts/contact-form';
import newsletter from './ts/newsletter';
import cookieBanner from './ts/cookie-banner';
import headerAnimation from './ts/header-animation';
import logoSlider from './ts/logo-slider';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

import './app.scss';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    faq.init();

    navigation.init();

    newsletter.init();

    banner.init();

    popup.init();

    cardSlider.init();

    accordion.init();

    accordionTab.init();

    AOS.init();

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll(
        '.swiper-container'
    ) as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        allSwiper.forEach(function (swiperElement) {
            if (!swiperElement.dataset.swiperOptions) return;
            // Set swiper element and swiper options from data-attribute
            swiper.init(
                swiperElement,
                JSON.parse(swiperElement.dataset.swiperOptions)
            );
        });
    }

    contactForm.init();

    // remove comment if you want to add a cookie banner
    cookieBanner.init();

    headerAnimation.init();

    logoSlider.init();
})();
