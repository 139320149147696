import KeenSlider from 'keen-slider';

export default {
    init() {
        const allLogoSliders = document.querySelectorAll(
            '.logo-section-autoplay'
        ) as NodeListOf<Element>;
        if (!allLogoSliders) {
            return;
        }

        allLogoSliders.forEach(function (logoSlider: Element) {
            const slider = logoSlider.querySelector(
                '.logo-slider-autoplay'
            ) as HTMLElement;
            if (!slider) {
                return;
            }

            const animation = { duration: 50000, easing: (t: number) => t };

            new KeenSlider(
                slider,
                {
                    loop: true,
                    renderMode: 'performance',
                    drag: true,

                    breakpoints: {
                        '(min-width: 300px)': {
                            slides: { perView: 1.8, spacing: 12 }
                        },

                        '(min-width: 640px)': {
                            slides: { perView: 2.8, spacing: 24 }
                        },

                        '(min-width: 768px)': {
                            slides: { perView: 3.3, spacing: 40 }
                        },

                        '(min-width: 1024px)': {
                            slides: { perView: 4, spacing: 40 }
                        },

                        '(min-width: 1280px)': {
                            slides: { perView: 4.7, spacing: 40 }
                        },

                        '(min-width: 1536px)': {
                            slides: { perView: 6, spacing: 40 }
                        }
                    },

                    created(s) {
                        s.moveToIdx(5, true, animation);
                    },
                    updated(s) {
                        s.moveToIdx(s.track.details.abs + 5, true, animation);
                    },
                    animationEnded(s) {
                        s.moveToIdx(s.track.details.abs + 5, true, animation);
                    }
                },
                [
                    (slider) => {
                        slider.on('created', () => {
                            slider.container.addEventListener(
                                'mouseover',
                                () => {
                                    slider.animator.stop();
                                }
                            );

                            slider.container.addEventListener(
                                'mouseout',
                                () => {
                                    slider.moveToIdx(
                                        slider.track.details.abs + 5,
                                        true,
                                        animation
                                    );
                                }
                            );
                        });
                    }
                ]
            );
        });
    }
};
