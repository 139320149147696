export default {
    init() {
        const closeButtonBanner = document.querySelector(
            '#banner-close'
        ) as HTMLElement;

        const header = document.querySelector('header') as HTMLElement;
        const contentDivBody = document.querySelector(
            '.content-div-body'
        ) as HTMLElement;
        const banner = document.querySelector('#banner') as HTMLElement;
        if (!closeButtonBanner || !banner) return;

        if (document.cookie.indexOf('banner_removed=1') !== -1) {
            // remove banner
            banner.classList.remove('fixed');
            banner.classList.remove('translate-y-0');
            banner.classList.add('-translate-y-8');
            banner.classList.add('hidden');

            contentDivBody.classList.remove('mt-40');
            contentDivBody.classList.add('mt-20');

            header.classList.remove('top-20');
            header.classList.add('top-0');
        } else {
            // show banner
            banner.classList.add('fixed');
            banner.classList.remove('-translate-y-8');
            banner.classList.add('translate-y-0');
            banner.classList.remove('hidden');

            header.classList.remove('top-0');
            header.classList.add('top-20');

            contentDivBody.classList.remove('mt-20');
            contentDivBody.classList.add('mt-40');
        }

        closeButtonBanner.addEventListener(
            'click',
            function (e) {
                e.preventDefault();
                banner.classList.remove('fixed');
                banner.classList.remove('translate-y-0');
                banner.classList.add('-translate-y-8');
                banner.classList.add('hidden');

                document.cookie = 'banner_removed=1';

                header.classList.remove('top-20');
                header.classList.add('top-0');

                contentDivBody.classList.remove('mt-40');
                contentDivBody.classList.add('mt-20');
            },
            false
        );
    }
};
