export default {
    init() {
        const newsletterFormContainers = document.querySelectorAll(
            '.newsletter'
        ) as NodeListOf<HTMLElement>;

        if (!newsletterFormContainers) {
            return;
        }

        newsletterFormContainers.forEach(function (
            newsletterContainer: HTMLElement
        ) {
            const forms = newsletterContainer.querySelectorAll(
                '#newsletter-form'
            ) as NodeListOf<HTMLElement>;
            if (forms.length) {
                forms.forEach(function (form: HTMLElement) {
                    const formId = form.dataset.formId;
                    if (formId) {
                        const currentForm = document.querySelector(
                            '#' + formId
                        );

                        if (!currentForm) return;

                        const formHandle = currentForm.querySelector(
                            'input[name="handle"]'
                        ) as HTMLInputElement;
                        if (!formHandle) {
                            return;
                        }
                        //Find the CSRF token hidden input, so we can replace it
                        const csrfInput = currentForm.querySelector(
                            'input[name="CRAFT_CSRF_TOKEN"]'
                        ) as HTMLInputElement;

                        if (!formHandle || !csrfInput) return;
                        // Fetch the new token for the form and replace the CSRF input with our new one
                        fetch(
                            '/actions/formie/forms/refresh-tokens?form=' +
                                formHandle.value
                        )
                            .then((result) => {
                                return result.json();
                            })
                            .then((result) => {
                                csrfInput.outerHTML = result.csrf.input;
                            });
                    }

                    // // tracking code
                    form.addEventListener('onAfterFormieSubmit', () => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const w = window as any;
                        w.dataLayer = w.dataLayer || [];

                        const formName = form.dataset.formName;
                        const formHandle = form.dataset.formHandle;
                        w.dataLayer.push({
                            event: 'formPageSubmission',
                            type: 'form',
                            'form-type': formHandle,
                            formName: formName
                        });
                    });
                });
            }
        });
    }
};
