export default {
    init() {
        const accordionTabs = document.querySelectorAll(
            '.accordion-tabs'
        ) as NodeListOf<Element>;
        if (!accordionTabs) {
            return;
        }
        // active first tab
        accordionTabs.forEach(function (accordion: Element) {
            const firstActiveContentDiv = accordion.querySelector(
                '.tab-content-div.active'
            ) as HTMLElement;
            const activeTarget = firstActiveContentDiv.dataset.targetIndex;

            const textDivs = accordion.querySelectorAll(
                '.hidden-tab-content'
            ) as NodeListOf<HTMLElement>;
            // remove hidden on first active tab
            textDivs.forEach((textDiv: HTMLElement) => {
                const activeTextDiv = textDiv.dataset.contentIndex;
                if (activeTarget === activeTextDiv) {
                    textDiv.classList.remove('hidden');
                }
            });

            const tabs = accordion.querySelectorAll(
                '.tab-content-div'
            ) as NodeListOf<HTMLElement>;

            // tab clicks
            if (tabs.length) {
                tabs.forEach((contentDiv: HTMLElement) => {
                    contentDiv.addEventListener(
                        'click',
                        function (event: Event) {
                            const activeContentDiv = accordion.querySelector(
                                '.tab-content-div.active'
                            ) as HTMLElement;

                            if (!activeContentDiv) {
                                return;
                            }

                            const activeTarget =
                                activeContentDiv.dataset.targetIndex;

                            if (!activeTarget) {
                                return;
                            }
                            event.stopPropagation();
                            const textDivs = accordion.querySelectorAll(
                                '.hidden-tab-content'
                            ) as NodeListOf<HTMLElement>;

                            if (
                                activeContentDiv &&
                                activeContentDiv != contentDiv
                            ) {
                                activeContentDiv.classList.remove('active');

                                textDivs.forEach((textDiv: HTMLElement) => {
                                    const activeTextDiv =
                                        textDiv.dataset.contentIndex;
                                    if (activeTarget === activeTextDiv) {
                                        textDiv.classList.add('hidden');
                                    }
                                });
                            }
                            const newActiveTarget =
                                contentDiv.dataset.targetIndex;

                            if (contentDiv.classList.contains('active')) {
                                contentDiv.classList.remove('active');
                                textDivs.forEach((textDiv: HTMLElement) => {
                                    const activeTextDiv =
                                        textDiv.dataset.contentIndex;
                                    if (newActiveTarget === activeTextDiv) {
                                        textDiv.classList.add('hidden');
                                    }
                                });
                            } else {
                                contentDiv.classList.add('active');
                                textDivs.forEach((textDiv: HTMLElement) => {
                                    const activeTextDiv =
                                        textDiv.dataset.contentIndex;
                                    if (newActiveTarget === activeTextDiv) {
                                        textDiv.classList.remove('hidden');
                                    }
                                });
                            }
                        }
                    );
                });
            }
        });

        // accordion in accordion
        const accordions = document.querySelectorAll(
            '.accordion-in-accordion'
        ) as NodeListOf<Element>;
        if (!accordions) {
            return;
        }
        // console.log(accordions);
        accordions.forEach(function (accordion: Element) {
            const firstActiveContentDiv = accordion.querySelector(
                '.content-div.active'
            ) as HTMLElement;
            const firstActiveHiddenDiv = firstActiveContentDiv?.querySelector(
                '.hidden-text-div'
            ) as HTMLElement;
            const firstActivePlusMinusToggle =
                firstActiveContentDiv?.querySelector(
                    '.plus-minus-toggle'
                ) as HTMLElement;
            const contentDivs = accordion.querySelectorAll('.content-div');

            if (firstActiveHiddenDiv) {
                firstActiveHiddenDiv.classList.add('max-h-fit');
                // firstActiveHiddenDiv.style.maxHeight =
                //     firstActiveHiddenDiv.scrollHeight + 25 + 'px';
            }
            if (firstActivePlusMinusToggle) {
                firstActivePlusMinusToggle.classList.remove('collapsed');
            }
            if (contentDivs.length) {
                contentDivs.forEach((contentDiv) => {
                    contentDiv.addEventListener(
                        'click',
                        function (event: Event) {
                            const activeContentDiv = accordion.querySelector(
                                '.content-div.active'
                            );
                            const plusMinusToggle =
                                contentDiv.querySelector('.plus-minus-toggle');
                            event.stopPropagation();
                            if (
                                activeContentDiv &&
                                activeContentDiv != contentDiv
                            ) {
                                activeContentDiv.classList.remove('active');
                                const activeTextDiv =
                                    activeContentDiv.querySelector(
                                        '.hidden-text-div'
                                    );
                                if (activeTextDiv) {
                                    activeTextDiv.classList.remove('max-h-fit');
                                    activeTextDiv.setAttribute(
                                        'style',
                                        'maxHeight:0;'
                                    );
                                }
                                const activeToggle =
                                    activeContentDiv.querySelector(
                                        '.plus-minus-toggle'
                                    );
                                if (activeToggle) {
                                    activeToggle.classList.add('collapsed');
                                }
                            }
                            const textDiv = contentDiv.querySelector(
                                '.hidden-text-div'
                            ) as HTMLElement;
                            if (contentDiv.classList.contains('active')) {
                                contentDiv.classList.remove('active');
                                if (plusMinusToggle) {
                                    plusMinusToggle.classList.add('collapsed');
                                }
                                if (textDiv) {
                                    textDiv.classList.remove('max-h-fit');
                                    textDiv.setAttribute(
                                        'style',
                                        'maxHeight:0;'
                                    );
                                }
                            } else {
                                contentDiv.classList.add('active');
                                if (textDiv) {
                                    // textDiv.style.maxHeight =
                                    //     textDiv.scrollHeight + 'px';
                                    textDiv.classList.add('max-h-fit');
                                }
                                if (plusMinusToggle) {
                                    plusMinusToggle.classList.remove(
                                        'collapsed'
                                    );
                                }
                            }
                        }
                    );
                });
            }
        });
    }
};
