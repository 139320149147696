export default {
    init() {
        const accordions = document.querySelectorAll(
            '.accordion'
        ) as NodeListOf<Element>;
        if (!accordions) {
            return;
        }

        accordions.forEach(function (accordion: Element) {
            const contentDivs = accordion.querySelectorAll('.content-div');

            if (contentDivs.length) {
                contentDivs.forEach((contentDiv) => {
                    contentDiv.addEventListener(
                        'click',
                        function (event: Event) {
                            const activeContentDiv = accordion.querySelector(
                                '.content-div.active'
                            );
                            const plusMinusToggle =
                                contentDiv.querySelector('.plus-minus-toggle');
                            event.stopPropagation();
                            if (
                                activeContentDiv &&
                                activeContentDiv != contentDiv
                            ) {
                                activeContentDiv.classList.remove('active');
                                const activeTextDiv =
                                    activeContentDiv.querySelector(
                                        '.hidden-text-div'
                                    );
                                if (activeTextDiv) {
                                    activeTextDiv.setAttribute(
                                        'style',
                                        'maxHeight:0;'
                                    );
                                }
                                const activeToggle =
                                    activeContentDiv.querySelector(
                                        '.plus-minus-toggle'
                                    );
                                if (activeToggle) {
                                    activeToggle.classList.add('collapsed');
                                }
                            }
                            const textDiv = contentDiv.querySelector(
                                '.hidden-text-div'
                            ) as HTMLElement;
                            if (contentDiv.classList.contains('active')) {
                                contentDiv.classList.remove('active');
                                if (plusMinusToggle) {
                                    plusMinusToggle.classList.add('collapsed');
                                }
                                if (textDiv) {
                                    textDiv.setAttribute(
                                        'style',
                                        'maxHeight:0;'
                                    );
                                }
                            } else {
                                contentDiv.classList.add('active');
                                if (textDiv) {
                                    textDiv.style.maxHeight =
                                        textDiv.scrollHeight + 'px';
                                }
                                if (plusMinusToggle) {
                                    plusMinusToggle.classList.remove(
                                        'collapsed'
                                    );
                                }
                            }
                        }
                    );
                });
            }

            if (accordion.classList.contains('first-tab-open')) {
                const firstActiveContentDiv = accordion.querySelector(
                    '.content-div.active'
                ) as HTMLElement;
                const firstActiveHiddenDiv =
                    firstActiveContentDiv.querySelector(
                        '.hidden-text-div'
                    ) as HTMLElement;
                const firstActivePlusMinusToggle =
                    firstActiveContentDiv.querySelector(
                        '.plus-minus-toggle'
                    ) as HTMLElement;

                if (firstActiveHiddenDiv) {
                    firstActiveHiddenDiv.style.maxHeight =
                        firstActiveHiddenDiv.scrollHeight + 20 + 'px';
                }
                if (firstActivePlusMinusToggle) {
                    firstActivePlusMinusToggle.classList.remove('collapsed');
                }
            } else {
                return;
            }
        });
    }
};
