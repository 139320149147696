export default {
    init() {
        const cardSliderSections = document.querySelectorAll(
            '.card-slider'
        ) as NodeListOf<Element>;
        if (!cardSliderSections) {
            return;
        }

        cardSliderSections.forEach(function (section: Element) {
            const infoIcons = section.querySelectorAll(
                '.info-icon'
            ) as NodeListOf<HTMLElement>;

            infoIcons.forEach((icon) => {
                icon.addEventListener('click', function (event) {
                    event.stopPropagation();
                    const activeCardIndex = icon.dataset.infoIcon;

                    if (!activeCardIndex) {
                        return;
                    }

                    const cards = section.querySelectorAll(
                        '.card-content'
                    ) as NodeListOf<HTMLElement>;

                    cards.forEach((card: HTMLElement) => {
                        const cardIndex = card.dataset.cardIndex;
                        if (activeCardIndex === cardIndex) {
                            card.classList.add('is-flipped');
                        }
                    });
                });
            });

            const closeIcons = section.querySelectorAll(
                '.close-icon'
            ) as NodeListOf<HTMLElement>;

            closeIcons.forEach((closeIcon) => {
                closeIcon.addEventListener('click', function (event) {
                    event.stopPropagation();
                    const activeCardIndex = closeIcon.dataset.closeIcon;

                    if (!activeCardIndex) {
                        return;
                    }

                    const cards = section.querySelectorAll(
                        '.card-content'
                    ) as NodeListOf<HTMLElement>;

                    cards.forEach((card: HTMLElement) => {
                        const cardIndex = card.dataset.cardIndex;
                        if (activeCardIndex === cardIndex) {
                            if (card.classList.contains('is-flipped')) {
                                card.classList.remove('is-flipped');
                            }
                        }
                    });
                });
            });
        });
    }
};
