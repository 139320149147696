import lottie from 'lottie-web';

// let successLottieAnimation;

export default {
    init() {
        const animationContainer = document.querySelector(
            '.lottie-player'
        ) as HTMLElement;

        if (!animationContainer) return;

        const animationUrl = animationContainer.dataset.animationUrl;
        if (!animationUrl) return;

        const lottieAnimation = lottie.loadAnimation({
            container: animationContainer,
            autoplay: true,
            path: animationUrl,
            loop: true
        });

        if (!lottieAnimation) {
            return;
        }
    }
};
