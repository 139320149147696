import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import anime from 'animejs';

export default {
    init() {
        //////////// Navigation
        const menu = document.getElementById('menu');
        const hamburger = document.getElementById('hamburger');
        const header = document.querySelector('.header-menu') as HTMLElement;
        const body = document.querySelector('body');
        if (!menu || !hamburger || !header || !body) {
            return;
        }

        // We listen to the resize event
        window.addEventListener('resize', () => {
            if (menu.classList.contains('open')) {
                menu.classList.remove('open');
                hamburger.classList.remove('close');
                header.classList.remove('open-menu');
                body.classList.remove('overflow-hidden');
                body.classList.remove('fixed');
            }
        });

        if (hamburger) {
            hamburger.addEventListener('click', function () {
                const timeline = anime.timeline({
                    duration: 400,
                    easing: 'easeOutQuint'
                });

                // close menu
                if (menu.classList.contains('open')) {
                    menu.classList.remove('open');
                    menu.classList.add('overflow-hidden');
                    menu.classList.remove('h-auto');
                    menu.classList.add('h-80');
                    hamburger.classList.remove('close');
                    header.classList.remove('open-menu');

                    timeline.add({
                        targets: '#menu',
                        opacity: 0,
                        complete: () => {
                            clearAllBodyScrollLocks();
                        }
                    });
                } else {
                    // open menu
                    disableBodyScroll(menu);
                    menu.classList.add('open');
                    menu.classList.remove('overflow-hidden');
                    menu.classList.remove('h-80');
                    menu.classList.add('h-auto');
                    hamburger.classList.add('close');
                    header.classList.add('open-menu');

                    timeline.add({
                        targets: '#menu',
                        opacity: 1
                    });
                }
            });
        }

        const menuItemDivs = document.querySelectorAll('.menu-item-div');

        if (menuItemDivs.length) {
            menuItemDivs.forEach((menuItemDiv) => {
                menuItemDiv.addEventListener('click', function (event) {
                    const activeContentDiv = menu.querySelector(
                        '.menu-item-div.active-tab'
                    );
                    const plusMinusToggle =
                        menuItemDiv.querySelector('.plus-minus-toggle');

                    const target = event.target as HTMLButtonElement;

                    if (target.tagName.toLowerCase() == 'a') {
                        return;
                    }

                    if (activeContentDiv && activeContentDiv != menuItemDiv) {
                        const activeSubbuttons = activeContentDiv.querySelector(
                            '.subbuttons'
                        ) as HTMLElement;
                        const activePlusMinusToggle =
                            activeContentDiv.querySelector(
                                '.plus-minus-toggle'
                            );
                        activeContentDiv.classList.remove('active-tab');
                        if (activeSubbuttons) {
                            activeSubbuttons.classList.add('max-h-0');
                        }
                        if (activePlusMinusToggle) {
                            activePlusMinusToggle.classList.add('collapsed');
                        }
                    }

                    const subbuttons = menuItemDiv.querySelector(
                        '.subbuttons'
                    ) as HTMLElement;

                    if (menuItemDiv.classList.contains('active-tab')) {
                        menuItemDiv.classList.remove('active-tab');
                        if (plusMinusToggle) {
                            plusMinusToggle.classList.add('collapsed');
                        }
                        if (subbuttons) {
                            subbuttons.classList.remove('max-h-fit');
                            subbuttons.classList.add('max-h-0');
                        }
                    } else {
                        menuItemDiv.classList.add('active-tab');
                        if (subbuttons) {
                            subbuttons.classList.add('max-h-fit');
                        }
                        if (plusMinusToggle) {
                            plusMinusToggle.classList.remove('collapsed');
                        }
                    }
                });
            });
        }

        // change header color on scroll
        function handleHeaderScroll(scrollTop: number) {
            if (!body) {
                return;
            }
            if (scrollTop <= 0 && header) {
                header.classList.add('header-bg-light');
                header.classList.remove('header-bg-dark');
                if (body) {
                    body.removeAttribute('class');
                    body.classList.add(header.dataset.color ?? '');
                }
            } else {
                if (header) {
                    header.classList.remove('header-bg-light');
                    header.classList.add('header-bg-dark');
                    body.removeAttribute('class');
                    body.classList.add('bg-primary-500');
                }
            }
        }

        handleHeaderScroll(
            window.pageYOffset || document.documentElement.scrollTop
        );

        window.addEventListener(
            'scroll',
            function () {
                const windowScrollTop =
                    window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
                handleHeaderScroll(windowScrollTop);
            },
            false
        );
    }
};
