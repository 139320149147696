export default {
    init() {
        const popupDivs = document.querySelectorAll(
            '#popup'
        ) as NodeListOf<HTMLElement>;

        if (!popupDivs) return;

        popupDivs.forEach((popup) => {
            const imageUrl = popup.dataset.backgroundImage;
            const popupId = popup.dataset.popupId;

            if (!imageUrl) return;
            popup.style.backgroundImage =
                // eslint-disable-next-line quotes
                "url('" + imageUrl + "')";

            document.addEventListener('scroll', function () {
                popup.classList.add('half-visible');
            });

            handleHeaderScroll(
                window.pageYOffset || document.documentElement.scrollTop
            );

            window.addEventListener(
                'scroll',
                function () {
                    const windowScrollTop =
                        window.pageYOffset ||
                        document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
                    handleHeaderScroll(windowScrollTop);
                },
                false
            );

            function handleHeaderScroll(scrollTop: number) {
                if (scrollTop <= 0) {
                    popup.classList.remove('half-visible');
                    popup.classList.add('not-visible');
                } else {
                    popup.classList.remove('not-visible');
                    popup.classList.add('half-visible');
                }
            }

            const closeIcon = popup.querySelector('#banner-close');
            if (!closeIcon) return;

            closeIcon.addEventListener('click', function () {
                popup.classList.remove('half-visible');
                popup.classList.add('hidden');
                document.cookie = popupId + 'popup_removed=1';
            });

            if (document.cookie.indexOf(popupId + 'popup_removed=1') !== -1) {
                popup.classList.add('hidden');
            }
        });
    }
};
